body {
	font-family: 'DM Sans';
}
.Parent {
	display: flex;
	justify-content: space-between;
	background-color: white;
	margin: 20px;
	opacity: 0.9;
}

.btn-sty {
	background-color: blueviolet;
	color: orange;
}

.main123 {
	width: 90.2% !important;
	height: 101px !important;
	margin: auto;
	margin-top: 12px !important;
	background-color: white;
	display: flex;
	justify-content: space-between;
}

.child-right {
	margin: auto 0;
	opacity: 0.9;
	margin-left: 30px;
}
.child-right button {
	padding: 10px;
	width: 150px;
	margin-left: 10px;
	border-radius: 20px;
	border: none;
	height: 40px;
}
.save_button {
	background-color: #4157ff;
	color: white;
	border: none;
	border-radius: 100%;
	width: 150px;
	height: 18px;
	cursor: pointer;
	font-size: 14px;
	font-weight: 700;
}
.verify_account {
	background-color: #4157ff;
	color: white;
	border: none;
	border-radius: 100%;
	width: 150px;
	height: 18px;
	cursor: pointer;
}
.contact-provider {
	background-color: #4cd964;
	cursor: pointer;
	color: white;
	/* border: none;
    width:100px */
}
.child-right {
	display: flex;
	margin-right: 30px;
}
