.profile-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	background: white;
	width: 246px;
	height: auto;
	border-radius: 12px;
	float: right;
	position: absolute;
	top: 50px;
	right: 60px;
	z-index: 500;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.firs-site {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
	gap: 1rem;
}

.admin-site {
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
}

.admin-site > :nth-child(1) {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 900;
	font-size: 16px;
}

.imge-profile {
	background: black;
	width: 81px;
	height: 81px;
	border-radius: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.imge-profile > :nth-child(1) {
	margin: 15px 19px;
	width: auto;
	height: 51px;
	/* height: 42px; */
	/* margin-left: 62px; */
}

.setting {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 40px;
	margin-top: 12px;
}

.account,
.changePassword,
.Lagout12 {
	font-size: 14px;
	font-weight: 400;
	color: #66737f;
	cursor: pointer;
}

.account-title {
	display: block;
	width: 224px;
	padding-left: 53px;
	display: flex;
	justify-content: unset;
}

.leftValue {
	margin-left: 12px;
}

.account-title:hover {
	background-color: #f7fbff;
}

/* .password {

    display: block;
    width: 224px;
    padding-left: 53px;
   
    display: flex;
    justify-content: unset;
} */

/* .password:hover {
background-color: #F7FBFF;

} */

.lagoutValue {
	display: block;
	width: 224px;
	padding-left: 53px;
	display: flex;
	justify-content: unset;
}

.lagoutValue:hover {
	background-color: #f7fbff;
}

.leftValue {
	margin-left: 10px;
}

.modaldesign {
	background-color: rgba(0, 0, 0, 0);
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
	background-color: rgba(0, 0, 0, 0) !important;
}
