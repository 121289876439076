.detail {
	background-color: #f8f9fe;
}

/* .info{
    width: 90%;
    margin: auto;
} */

.home {
	display: flex;
}

.homeContainer {
	flex: 5;
}

.detail-header {
	/* width: 90%; */
	background-color: white;
	min-height: 250px;
	height: auto;
	margin: auto;
}

/* .detail-main {
 
    margin-left: 20px;
   

} */
.password-change span {
	opacity: 0.9;
}
.info span {
	opacity: 0.9;
}
.save-btn {
	width: 90%;
	margin: auto;
	margin-top: 25px;
}
.detail-main p {
	opacity: 0.8;
}

.save-btn button {
	background-color: #4157ff;
	color: white;
	border: none;
	border-radius: 100%;
	width: 150px;
	height: 18px;
	padding: 10px;
	width: 150px;
	/* margin-left: 20px; */
	border-radius: 20px;
	border: none;
	height: 40px;
}
.savebutton {
	width: 155.1px;
	height: 47px;
	cursor: pointer;
}
.infColor {
	color: #66737f;
}
.boldinfo {
	font-weight: 600;
}
