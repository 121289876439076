.pg-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
	background-image: url('../../asset/image/bg.svg');
	background-size: cover;
	background-repeat: no-repeat;
	color: #ddd;
}
