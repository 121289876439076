/* .sidebar{
   
    min-height: 100vh;
    width: 250px;
   
    background: #FFFFFF;
    box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
    box-sizing: border-box;
    top: 0;
    left: 0;
} */
/* .sidebar-menu{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 97vh;
} */
.top{
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: auto;
    position: relative;
    /* margin-left: 32px; */
    left: 43px;
    
} 
.top>:nth-child(1){
    width: 142.83px;
    height: 28px;
    background: transparent;
}
.top>:nth-child(2){
    width: 143.17px;
    height: 26.41px;
    background: transparent;
}
.center{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;  
  
    margin-bottom: 230px;
}
/* .span-sidebar{
    display: block;
    background-color: #F7FBFF ;
   
    
} */
/* .sidebar span{
    display: block;
}
.span-sidebar-one.active{
    background:  #F7FBFF;
    color: black;

    border: none;
   
}
.span-sidebar-one:hover{
    background:  #F7FBFF;
    color: black;
   
}
.span-sidebar-two{
    background:   #F7FBFF;
    color: black;  
}
.span-sidebar-three:hover{
    background:   #F7FBFF;
    color: black;
    
   
}
.span-sidebar-four:hover{
    background:  #F7FBFF;
    color: black;
   
}
.span-sidebar-five:hover{
    background:  #F7FBFF;
    color: black;
   
}
.span-sidebar-six:hover{
    background:  #F7FBFF;
    color: black;
   
}
.span-sidebar-seven:hover{
    background:  #F7FBFF;;
   
}
.span-sidebar-eight:hover{
    background:  #F7FBFF;
    color: black;
   
}
.span-sidebar-nine:hover{
    background:  #F7FBFF;
    color: black;
   
} */

/* .bars{
    display: none;
} */


/* .center span{
   
display: flex;
flex-direction: row;
align-items: center;
padding: 0px 40px;
gap: 10px;
width: 250px;
height: 40px;
flex: none;
order: 1;
flex-grow: 0;
    
    
} */


.sidebarLogout_button{

  color: white ;
  background: #4157FF !important;
  border-radius: 56px !important;
  border: none;
  Width:203px;
  Height:50px;
  cursor: pointer;
  font-weight: 700 !important;
  font-family:' DM Sans';
}
/* .button-site{
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 200px;
} */
/* .center span{
    
    cursor: pointer;
  
} */




