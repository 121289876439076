.home {
	display: flex;
	background: #f8f9fe;
	height: 100vh;
}
.homeContainer {
	flex: 5;
	background: #f8f9fe;
	display: block;
}

::-webkit-scrollbar {
	width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #939396;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #acacaf;
}
