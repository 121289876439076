.spinnerCOntainer{
    /* background-image: url('../../asset/image/bg.svg'); */
    background-color: #F7FBFF;
    height: 100vh;
    position: relative;
}
.spinner{
    position: absolute;
    top: 45%;
    left: 45%;
}