@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
body {
  font-family: 'DM Sans', sans-serif;
}

.main {
  background-image: url('../../asset/image/bg.svg');
  height: 100%;

  background-repeat: no-repeat;
  background-size: cover;
  /* position: relative; */
  ;
  height: 100%;
}


.container {
  width: 445px;
  height: 553px;
  /* height: 520px; */
  /* margin-top: 15vh; */
  margin: auto auto;
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.logo {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textheading {
  font-size: 24px !important;
  /* font-weight: 400; */
  /* font-family: 'DM Sans'; */
  line-height: 28px !important;
  font-weight: 500 !important;
  font-style: normal;
  font-family: 'DM Sans' !important;
  color: #23262F !important;

}

.form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 70%;


}

.btn {
  border-radius: 30px;
  padding: 10px;
  width: 100%;
  color: white;
  font-size: 20px;
  font-weight: bold;
  /* margin-bottom: 30px; */
  background-color: #4157FF;
  border: none;
  cursor: pointer;
}

.btn-group {
  width: 60%;
  margin: auto;
  margin-top: 8%;
}


.footer Link {
  text-decoration: none;
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 400px;
  margin: auto;
  color: white;
  margin-top: 12px;

}

.link-styling {
  text-decoration: none;
  color: white ;}