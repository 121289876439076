.homeMember {
	display: flex;
	background: #f8f9fe;
	height: 100vh;
}

.homeContainerMembers {
	flex: 5;
	background: #f8f9fe;
	display: block;
}

/* ::-webkit-scrollbar {
    width: 12px;
}


::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #939396;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #acacaf;
} */
