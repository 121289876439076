/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/
.StripeElement {
	height: 30px;
	padding: 7px 12px;
	width: 400px;
	color: #32325d;
	background-color: white;
	border: 1px solid gray;
	border-radius: 4px;
}
.StripeElement--focus {
	box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement--invalid {
	border-color: #fa755a;
}
.StripeElement--webkit-autofill {
	background-color: #fefde5 !important;
}
