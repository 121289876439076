.detail {
	background-color: #f8f9fe;
}

.active {
	border-bottom: 2px solid #4157ff;
}

.info {
	width: 90%;
	margin: auto;
	margin-bottom: 5px;
}

.home {
	display: flex;
}

.homeContainer {
	flex: 5;
}

.detail-header {
	width: 90%;
	background-color: white;
	/* min-height: 350px; */
	/* height: 415px; */
	height: auto;
	margin: auto;
}

.details_edit {
	width: 90%;
	background-color: white;
	min-height: 470px;
	height: 470px;
	margin: auto;
}

.info-color {
	display: flex;
	margin-top: auto;
	/* overflow-wrap:'break-word'; */
	/* width: 90%; */
	word-break: break-word;
	
}
.info-color > .fontWeight-info {
	font-weight: bold;
}

.detail-main {
	margin-left: 20px;
}

.detail_main_edit {
	margin-left: 20px;
}
.detail_main_patient {
	margin-left: 20px;
}
.user_info_patient span {
	opacity: 1;
}
.info-content {
	background: '#F8F9FE';
}

.user-info span {
	opacity: 0.7;
}
.user_info_transactions {
	opacity: 1;
}
.user_info_Update {
	opacity: 1;
}
.info span {
	opacity: 0.7;
	margin-right: 10px;
	cursor: pointer;
}

.detail-main p {
	opacity: 0.7;
	/* margin-top:30px */
}

.info-span {
	font-weight: 600;
	color: #949494;
}

.upload-span {
	font-weight: 600;
	color: #949494;
}
