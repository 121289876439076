.topMember {
	display: flex;
	text-align: center;
	justify-content: center;
	margin-top: auto;
	position: relative;
	/* margin-left: 32px; */
	left: 43px;
}

.topMember > :nth-child(1) {
	width: 142.83px;
	height: 28px;
	background: transparent;
}

.topMember > :nth-child(2) {
	width: 143.17px;
	height: 26.41px;
	background: transparent;
}

.center {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	justify-content: center;

	margin-bottom: 230px;
}
