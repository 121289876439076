.character {
  border: none;
  font-size: 20px;
  border-radius: 8px;
  color: #272729;
  background-color: #f7f7f7;
}
.verify_btn-group {
  width: 57%;
  margin: auto;
  margin-top: 26%;
}
.verify_container {
  width: 475px;
  min-height: 700px;
  margin: auto auto;
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.disabled {
  /* border: 1px solid #999999; */
  /* background-color: #cccccc; */
  /* color: #cbeef7; */
  /* cursor: not-allowed; */
}
