@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

body {
/* min-height: 100vh;
width: 100%;
height: 100vh;
margin: 0; */
}

.textheading {
	font-size: 24px !important;
	line-height: 28px !important;
	font-weight: 500 !important ;
	font-style: normal;
	font-family: 'DM Sans' !important;
	color: #23262f !important;
}
.emp_form_signup {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 70%;
}

.emp_btn {
	border-radius: 56px;
	padding: 20px;
	width: 328.38px;
	height: 76px;
	color: white;
	font-size: 16px;
	font-weight: 700;
	/* margin-bottom: 30px; */
	background-color: #4157ff;
	border: none;
	cursor: pointer;
}

.emp_btn-group {
	width: 100%;
	margin: auto;
	margin-top: 8%;
}

.footer Link {
	text-decoration: none;
}

.link-styling {
	text-decoration: none;
	color: white;
}
.mailField {
	border: none !important;
	box-shadow: 0px 0px 1px rgb(0 0 0 / 2%), 0px 1px 3px rgb(50 50 93 / 15%) !important;
	background: #fff !important;
}
.fromGroupleft {
	margin-left: 8px;
}

.red {
	color: rgb(241, 100, 100);
}
