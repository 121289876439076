.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
	box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%),
		0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
}

.textcolorchange {
	color: #6e757c !important;
}

.css-13xy2my {
	box-shadow: rgb(0 0 0 / 20%) 0px 0px 0px 0, rgb(0 0 0 / 14%) 0px 0px 0px 0px,
		rgb(0 0 0 / 12%) 0px 0px 0px 0px !important;
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
	box-shadow: rgb(0 0 0 / 20%) 0px 0px 0px 0, rgb(0 0 0 / 14%) 0px 0px 0px 0px,
		rgb(0 0 0 / 12%) 0px 0px 0px 0px !important;
	background-color: #f8f9fa !important;
}
